////
/// @group colors
////


// Material Design color palette variables
// @link https://www.google.com/design/spec/style/color.html#color-color-palette

/// Boolean if a class name for each color should be created.
/// @type Boolean
$md-colors-include-class-names: false !default;

/// Boolean if the there should be warnings about colors not being from material design. This
/// is enabled by default to alert users of the lib to set the correct fallback colors.
/// @type Boolean
/// @see md-data-table-contextual-fallback-color
/// @see md-linear-progress-fallback-color
/// @see md-switch-ball-fallback-color
/// @see md-slider-warn-md
/// @see validate-slider-primary-color
/// @group colors, themes
$md-colors-warn-md: true !default;

/// @type Color
$md-red-50: #ffebee;

/// @type Color
$md-red-100: #ffcdd2;

/// @type Color
$md-red-200: #ef9a9a;

/// @type Color
$md-red-300: #e57373;

/// @type Color
$md-red-400: #ef5350;

/// @type Color
$md-red-500: #f44336;

/// @type Color
$md-red-600: #e53935;

/// @type Color
$md-red-700: #d32f2f;

/// @type Color
$md-red-800: #c62828;

/// @type Color
$md-red-900: #b71c1c;

/// @type Color
$md-red-a-100: #ff8a80;

/// @type Color
$md-red-a-200: #ff5252;

/// @type Color
$md-red-a-400: #ff1744;

/// @type Color
$md-red-a-700: #d50000;



/// @type Color
$md-pink-50: #fce4ec;

/// @type Color
$md-pink-100: #f8bbd0;

/// @type Color
$md-pink-200: #f48fb1;

/// @type Color
$md-pink-300: #f06292;

/// @type Color
$md-pink-400: #ec407a;

/// @type Color
$md-pink-500: #e91e63;

/// @type Color
$md-pink-600: #d81b60;

/// @type Color
$md-pink-700: #c2185b;

/// @type Color
$md-pink-800: #ad1457;

/// @type Color
$md-pink-900: #880e4f;


/// @type Color
$md-pink-a-100: #ff80ab;

/// @type Color
$md-pink-a-200: #ff4081;

/// @type Color
$md-pink-a-400: #f50057;

/// @type Color
$md-pink-a-700: #c51162;



/// @type Color
$md-purple-50: #f3e5f5;

/// @type Color
$md-purple-100: #e1bee7;

/// @type Color
$md-purple-200: #ce93d8;

/// @type Color
$md-purple-300: #ba68c8;

/// @type Color
$md-purple-400: #ab47bc;

/// @type Color
$md-purple-500: #9c27b0;

/// @type Color
$md-purple-600: #8e24aa;

/// @type Color
$md-purple-700: #7b1fa2;

/// @type Color
$md-purple-800: #6a1b9a;

/// @type Color
$md-purple-900: #4a148c;


/// @type Color
$md-purple-a-100: #ea80fc;

/// @type Color
$md-purple-a-200: #e040fb;

/// @type Color
$md-purple-a-400: #d500f9;

/// @type Color
$md-purple-a-700: #a0f;



/// @type Color
$md-deep-purple-50: #ede7f6;

/// @type Color
$md-deep-purple-100: #d1c4e9;

/// @type Color
$md-deep-purple-200: #b39ddb;

/// @type Color
$md-deep-purple-300: #9575cd;

/// @type Color
$md-deep-purple-400: #7e57c2;

/// @type Color
$md-deep-purple-500: #673ab7;

/// @type Color
$md-deep-purple-600: #5e35b1;

/// @type Color
$md-deep-purple-700: #512da8;

/// @type Color
$md-deep-purple-800: #4527a0;

/// @type Color
$md-deep-purple-900: #311b92;


/// @type Color
$md-deep-purple-a-100: #b388ff;

/// @type Color
$md-deep-purple-a-200: #7c4dff;

/// @type Color
$md-deep-purple-a-400: #651fff;

/// @type Color
$md-deep-purple-a-700: #6200ea;



/// @type Color
$md-indigo-50: #e8eaf6;

/// @type Color
$md-indigo-100: #c5cae9;

/// @type Color
$md-indigo-200: #9fa8da;

/// @type Color
$md-indigo-300: #7986cb;

/// @type Color
$md-indigo-400: #5c6bc0;

/// @type Color
$md-indigo-500: #3f51b5;

/// @type Color
$md-indigo-600: #3949ab;

/// @type Color
$md-indigo-700: #303f9f;

/// @type Color
$md-indigo-800: #283593;

/// @type Color
$md-indigo-900: #1a237e;


/// @type Color
$md-indigo-a-100: #8c9eff;

/// @type Color
$md-indigo-a-200: #536dfe;

/// @type Color
$md-indigo-a-400: #3d5afe;

/// @type Color
$md-indigo-a-700: #304ffe;


/// @type Color
$md-blue-50: #e3f2fd;

/// @type Color
$md-blue-100: #bbdefb;

/// @type Color
$md-blue-200: #90caf9;

/// @type Color
$md-blue-300: #64b5f6;

/// @type Color
$md-blue-400: #42a5f5;

/// @type Color
$md-blue-500: #2196f3;

/// @type Color
$md-blue-600: #1e88e5;

/// @type Color
$md-blue-700: #1976d2;

/// @type Color
$md-blue-800: #1565c0;

/// @type Color
$md-blue-900: #0d47a1;


/// @type Color
$md-blue-a-100: #82b1ff;

/// @type Color
$md-blue-a-200: #448aff;

/// @type Color
$md-blue-a-400: #2979ff;

/// @type Color
$md-blue-a-700: #2962ff;



/// @type Color
$md-light-blue-50: #e1f5fe;

/// @type Color
$md-light-blue-100: #b3e5fc;

/// @type Color
$md-light-blue-200: #81d4fa;

/// @type Color
$md-light-blue-300: #4fc3f7;

/// @type Color
$md-light-blue-400: #29b6f6;

/// @type Color
$md-light-blue-500: #03a9f4;

/// @type Color
$md-light-blue-600: #039be5;

/// @type Color
$md-light-blue-700: #0288d1;

/// @type Color
$md-light-blue-800: #0277bd;

/// @type Color
$md-light-blue-900: #01579b;


/// @type Color
$md-light-blue-a-100: #80d8ff;

/// @type Color
$md-light-blue-a-200: #40c4ff;

/// @type Color
$md-light-blue-a-400: #00b0ff;

/// @type Color
$md-light-blue-a-700: #0091ea;



/// @type Color
$md-cyan-50: #e0f7fa;

/// @type Color
$md-cyan-100: #b2ebf2;

/// @type Color
$md-cyan-200: #80deea;

/// @type Color
$md-cyan-300: #4dd0e1;

/// @type Color
$md-cyan-400: #26c6da;

/// @type Color
$md-cyan-500: #00bcd4;

/// @type Color
$md-cyan-600: #00acc1;

/// @type Color
$md-cyan-700: #0097a7;

/// @type Color
$md-cyan-800: #00838f;

/// @type Color
$md-cyan-900: #006064;


/// @type Color
$md-cyan-a-100: #84ffff;

/// @type Color
$md-cyan-a-200: #18ffff;

/// @type Color
$md-cyan-a-400: #00e5ff;

/// @type Color
$md-cyan-a-700: #00b8d4;



/// @type Color
$md-teal-50: #e0f2f1;

/// @type Color
$md-teal-100: #b2dfdb;

/// @type Color
$md-teal-200: #80cbc4;

/// @type Color
$md-teal-300: #4db6ac;

/// @type Color
$md-teal-400: #26a69a;

/// @type Color
$md-teal-500: #009688;

/// @type Color
$md-teal-600: #00897b;

/// @type Color
$md-teal-700: #00796b;

/// @type Color
$md-teal-800: #00695c;

/// @type Color
$md-teal-900: #004d40;


/// @type Color
$md-teal-a-100: #a7ffeb;

/// @type Color
$md-teal-a-200: #64ffda;

/// @type Color
$md-teal-a-400: #1de9b6;

/// @type Color
$md-teal-a-700: #00bfa5;



/// @type Color
$md-green-50: #e8f5e9;

/// @type Color
$md-green-100: #c8e6c9;

/// @type Color
$md-green-200: #a5d6a7;

/// @type Color
$md-green-300: #81c784;

/// @type Color
$md-green-400: #66bb6a;

/// @type Color
$md-green-500: #4caf50;

/// @type Color
$md-green-600: #43a047;

/// @type Color
$md-green-700: #388e3c;

/// @type Color
$md-green-800: #2e7d32;

/// @type Color
$md-green-900: #1b5e20;


/// @type Color
$md-green-a-100: #b9f6ca;

/// @type Color
$md-green-a-200: #69f0ae;

/// @type Color
$md-green-a-400: #00e676;

/// @type Color
$md-green-a-700: #00c853;



/// @type Color
$md-light-green-50: #f1f8e9;

/// @type Color
$md-light-green-100: #dcedc8;

/// @type Color
$md-light-green-200: #c5e1a5;

/// @type Color
$md-light-green-300: #aed581;

/// @type Color
$md-light-green-400: #9ccc65;

/// @type Color
$md-light-green-500: #8bc34a;

/// @type Color
$md-light-green-600: #7cb342;

/// @type Color
$md-light-green-700: #689f38;

/// @type Color
$md-light-green-800: #558b2f;

/// @type Color
$md-light-green-900: #33691e;


/// @type Color
$md-light-green-a-100: #ccff90;

/// @type Color
$md-light-green-a-200: #b2ff59;

/// @type Color
$md-light-green-a-400: #76ff03;

/// @type Color
$md-light-green-a-700: #64dd17;



/// @type Color
$md-lime-50: #f9fbe7;

/// @type Color
$md-lime-100: #f0f4c3;

/// @type Color
$md-lime-200: #e6ee9c;

/// @type Color
$md-lime-300: #dce775;

/// @type Color
$md-lime-400: #d4e157;

/// @type Color
$md-lime-500: #cddc39;

/// @type Color
$md-lime-600: #c0ca33;

/// @type Color
$md-lime-700: #afb42b;

/// @type Color
$md-lime-800: #9e9d24;

/// @type Color
$md-lime-900: #827717;


/// @type Color
$md-lime-a-100: #f4ff81;

/// @type Color
$md-lime-a-200: #eeff41;

/// @type Color
$md-lime-a-400: #c6ff00;

/// @type Color
$md-lime-a-700: #aeea00;



/// @type Color
$md-yellow-50: #fffde7;

/// @type Color
$md-yellow-100: #fff9c4;

/// @type Color
$md-yellow-200: #fff59d;

/// @type Color
$md-yellow-300: #fff176;

/// @type Color
$md-yellow-400: #ffee58;

/// @type Color
$md-yellow-500: #ffeb3b;

/// @type Color
$md-yellow-600: #fdd835;

/// @type Color
$md-yellow-700: #fbc02d;

/// @type Color
$md-yellow-800: #f9a825;

/// @type Color
$md-yellow-900: #f57f17;


/// @type Color
$md-yellow-a-100: #ffff8d;

/// @type Color
$md-yellow-a-200: #ff0;

/// @type Color
$md-yellow-a-400: #ffea00;

/// @type Color
$md-yellow-a-700: #ffd600;



/// @type Color
$md-amber-50: #fff8e1;

/// @type Color
$md-amber-100: #ffecb3;

/// @type Color
$md-amber-200: #ffe082;

/// @type Color
$md-amber-300: #ffd54f;

/// @type Color
$md-amber-400: #ffca28;

/// @type Color
$md-amber-500: #ffc107;

/// @type Color
$md-amber-600: #ffb300;

/// @type Color
$md-amber-700: #ffa000;

/// @type Color
$md-amber-800: #ff8f00;

/// @type Color
$md-amber-900: #ff6f00;


/// @type Color
$md-amber-a-100: #ffe57f;

/// @type Color
$md-amber-a-200: #ffd740;

/// @type Color
$md-amber-a-400: #ffc400;

/// @type Color
$md-amber-a-700: #ffab00;



/// @type Color
$md-orange-50: #fff3e0;

/// @type Color
$md-orange-100: #fff0b2;

/// @type Color
$md-orange-200: #ffcc80;

/// @type Color
$md-orange-300: #ffb74d;

/// @type Color
$md-orange-400: #ffa726;

/// @type Color
$md-orange-500: #ff9800;

/// @type Color
$md-orange-600: #fb8c00;

/// @type Color
$md-orange-700: #f57c00;

/// @type Color
$md-orange-800: #ef6c00;

/// @type Color
$md-orange-900: #e65100;


/// @type Color
$md-orange-a-100: #ffd180;

/// @type Color
$md-orange-a-200: #ffab40;

/// @type Color
$md-orange-a-400: #ff9100;

/// @type Color
$md-orange-a-700: #ff6d00;



/// @type Color
$md-deep-orange-50: #fbe9e7;

/// @type Color
$md-deep-orange-100: #ffccbc;

/// @type Color
$md-deep-orange-200: #ffab91;

/// @type Color
$md-deep-orange-300: #ff8a65;

/// @type Color
$md-deep-orange-400: #ff7043;

/// @type Color
$md-deep-orange-500: #ff5722;

/// @type Color
$md-deep-orange-600: #f4511e;

/// @type Color
$md-deep-orange-700: #e64a19;

/// @type Color
$md-deep-orange-800: #d84315;

/// @type Color
$md-deep-orange-900: #bf360c;


/// @type Color
$md-deep-orange-a-100: #ff9e80;

/// @type Color
$md-deep-orange-a-200: #ff6e40;

/// @type Color
$md-deep-orange-a-400: #ff3d00;

/// @type Color
$md-deep-orange-a-700: #dd2c00;



/// @type Color
$md-brown-50: #efebe9;

/// @type Color
$md-brown-100: #d7ccc8;

/// @type Color
$md-brown-200: #bcaaa4;

/// @type Color
$md-brown-300: #a1887f;

/// @type Color
$md-brown-400: #8d6e63;

/// @type Color
$md-brown-500: #795548;

/// @type Color
$md-brown-600: #6d4c41;

/// @type Color
$md-brown-700: #5d4037;

/// @type Color
$md-brown-800: #4e342e;

/// @type Color
$md-brown-900: #3e2723;



/// @type Color
$md-grey-50: #fafafa;

/// @type Color
$md-grey-100: #f5f5f5;

/// @type Color
$md-grey-200: #eee;

/// @type Color
$md-grey-300: #e0e0e0;

/// @type Color
$md-grey-400: #bdbdbd;

/// @type Color
$md-grey-500: #9e9e9e;

/// @type Color
$md-grey-600: #757575;

/// @type Color
$md-grey-700: #616161;

/// @type Color
$md-grey-800: #424242;

/// @type Color
$md-grey-900: #212121;



/// @type Color
$md-blue-grey-50: #eceff1;

/// @type Color
$md-blue-grey-100: #cfd8dc;

/// @type Color
$md-blue-grey-200: #b0bec5;

/// @type Color
$md-blue-grey-300: #90a4ae;

/// @type Color
$md-blue-grey-400: #78909c;

/// @type Color
$md-blue-grey-500: #607d8b;

/// @type Color
$md-blue-grey-600: #546e7a;

/// @type Color
$md-blue-grey-700: #455a64;

/// @type Color
$md-blue-grey-800: #37474f;

/// @type Color
$md-blue-grey-900: #263238;





/// @type Color
$md-black-base: #000;

/// @type Color
$md-white-base: #fff;

/// This is a map of all the material design base colors so that you can programmatically get
/// variables with the neat sass-map functions.
///
/// @example scss - Example Usage SCSS
///   .error-field {
///     background: map-get(md-red-300, $md-color-map);
///   }
///
/// @type Map
$md-color-map: (
  md-red-50: $md-red-50,
  md-red-100: $md-red-100,
  md-red-200: $md-red-200,
  md-red-300: $md-red-300,
  md-red-400: $md-red-400,
  md-red-500: $md-red-500,
  md-red-600: $md-red-600,
  md-red-700: $md-red-700,
  md-red-800: $md-red-800,
  md-red-900: $md-red-900,
  md-red-a-100: $md-red-a-100,
  md-red-a-200: $md-red-a-200,
  md-red-a-400: $md-red-a-400,
  md-red-a-700: $md-red-a-700,
  md-pink-50: $md-pink-50,
  md-pink-100: $md-pink-100,
  md-pink-200: $md-pink-200,
  md-pink-300: $md-pink-300,
  md-pink-400: $md-pink-400,
  md-pink-500: $md-pink-500,
  md-pink-600: $md-pink-600,
  md-pink-700: $md-pink-700,
  md-pink-800: $md-pink-800,
  md-pink-900: $md-pink-900,
  md-pink-a-100: $md-pink-a-100,
  md-pink-a-200: $md-pink-a-200,
  md-pink-a-400: $md-pink-a-400,
  md-pink-a-700: $md-pink-a-700,
  md-purple-50: $md-purple-50,
  md-purple-100: $md-purple-100,
  md-purple-200: $md-purple-200,
  md-purple-300: $md-purple-300,
  md-purple-400: $md-purple-400,
  md-purple-500: $md-purple-500,
  md-purple-600: $md-purple-600,
  md-purple-700: $md-purple-700,
  md-purple-800: $md-purple-800,
  md-purple-900: $md-purple-900,
  md-purple-a-100: $md-purple-a-100,
  md-purple-a-200: $md-purple-a-200,
  md-purple-a-400: $md-purple-a-400,
  md-purple-a-700: $md-purple-a-700,
  md-deep-purple-50: $md-deep-purple-50,
  md-deep-purple-100: $md-deep-purple-100,
  md-deep-purple-200: $md-deep-purple-200,
  md-deep-purple-300: $md-deep-purple-300,
  md-deep-purple-400: $md-deep-purple-400,
  md-deep-purple-500: $md-deep-purple-500,
  md-deep-purple-600: $md-deep-purple-600,
  md-deep-purple-700: $md-deep-purple-700,
  md-deep-purple-800: $md-deep-purple-800,
  md-deep-purple-900: $md-deep-purple-900,
  md-deep-purple-a-100: $md-deep-purple-a-100,
  md-deep-purple-a-200: $md-deep-purple-a-200,
  md-deep-purple-a-400: $md-deep-purple-a-400,
  md-deep-purple-a-700: $md-deep-purple-a-700,
  md-indigo-50: $md-indigo-50,
  md-indigo-100: $md-indigo-100,
  md-indigo-200: $md-indigo-200,
  md-indigo-300: $md-indigo-300,
  md-indigo-400: $md-indigo-400,
  md-indigo-500: $md-indigo-500,
  md-indigo-600: $md-indigo-600,
  md-indigo-700: $md-indigo-700,
  md-indigo-800: $md-indigo-800,
  md-indigo-900: $md-indigo-900,
  md-indigo-a-100: $md-indigo-a-100,
  md-indigo-a-200: $md-indigo-a-200,
  md-indigo-a-400: $md-indigo-a-400,
  md-indigo-a-700: $md-indigo-a-700,
  md-blue-50: $md-blue-50,
  md-blue-100: $md-blue-100,
  md-blue-200: $md-blue-200,
  md-blue-300: $md-blue-300,
  md-blue-400: $md-blue-400,
  md-blue-500: $md-blue-500,
  md-blue-600: $md-blue-600,
  md-blue-700: $md-blue-700,
  md-blue-800: $md-blue-800,
  md-blue-900: $md-blue-900,
  md-blue-a-100: $md-blue-a-100,
  md-blue-a-200: $md-blue-a-200,
  md-blue-a-400: $md-blue-a-400,
  md-blue-a-700: $md-blue-a-700,
  md-light-blue-50: $md-light-blue-50,
  md-light-blue-100: $md-light-blue-100,
  md-light-blue-200: $md-light-blue-200,
  md-light-blue-300: $md-light-blue-300,
  md-light-blue-400: $md-light-blue-400,
  md-light-blue-500: $md-light-blue-500,
  md-light-blue-600: $md-light-blue-600,
  md-light-blue-700: $md-light-blue-700,
  md-light-blue-800: $md-light-blue-800,
  md-light-blue-900: $md-light-blue-900,
  md-light-blue-a-100: $md-light-blue-a-100,
  md-light-blue-a-200: $md-light-blue-a-200,
  md-light-blue-a-400: $md-light-blue-a-400,
  md-light-blue-a-700: $md-light-blue-a-700,
  md-cyan-50: $md-cyan-50,
  md-cyan-100: $md-cyan-100,
  md-cyan-200: $md-cyan-200,
  md-cyan-300: $md-cyan-300,
  md-cyan-400: $md-cyan-400,
  md-cyan-500: $md-cyan-500,
  md-cyan-600: $md-cyan-600,
  md-cyan-700: $md-cyan-700,
  md-cyan-800: $md-cyan-800,
  md-cyan-900: $md-cyan-900,
  md-cyan-a-100: $md-cyan-a-100,
  md-cyan-a-200: $md-cyan-a-200,
  md-cyan-a-400: $md-cyan-a-400,
  md-cyan-a-700: $md-cyan-a-700,
  md-teal-50: $md-teal-50,
  md-teal-100: $md-teal-100,
  md-teal-200: $md-teal-200,
  md-teal-300: $md-teal-300,
  md-teal-400: $md-teal-400,
  md-teal-500: $md-teal-500,
  md-teal-600: $md-teal-600,
  md-teal-700: $md-teal-700,
  md-teal-800: $md-teal-800,
  md-teal-900: $md-teal-900,
  md-teal-a-100: $md-teal-a-100,
  md-teal-a-200: $md-teal-a-200,
  md-teal-a-400: $md-teal-a-400,
  md-teal-a-700: $md-teal-a-700,
  md-green-50: $md-green-50,
  md-green-100: $md-green-100,
  md-green-200: $md-green-200,
  md-green-300: $md-green-300,
  md-green-400: $md-green-400,
  md-green-500: $md-green-500,
  md-green-600: $md-green-600,
  md-green-700: $md-green-700,
  md-green-800: $md-green-800,
  md-green-900: $md-green-900,
  md-green-a-100: $md-green-a-100,
  md-green-a-200: $md-green-a-200,
  md-green-a-400: $md-green-a-400,
  md-green-a-700: $md-green-a-700,
  md-light-green-50: $md-light-green-50,
  md-light-green-100: $md-light-green-100,
  md-light-green-200: $md-light-green-200,
  md-light-green-300: $md-light-green-300,
  md-light-green-400: $md-light-green-400,
  md-light-green-500: $md-light-green-500,
  md-light-green-600: $md-light-green-600,
  md-light-green-700: $md-light-green-700,
  md-light-green-800: $md-light-green-800,
  md-light-green-900: $md-light-green-900,
  md-light-green-a-100: $md-light-green-a-100,
  md-light-green-a-200: $md-light-green-a-200,
  md-light-green-a-400: $md-light-green-a-400,
  md-light-green-a-700: $md-light-green-a-700,
  md-lime-50: $md-lime-50,
  md-lime-100: $md-lime-100,
  md-lime-200: $md-lime-200,
  md-lime-300: $md-lime-300,
  md-lime-400: $md-lime-400,
  md-lime-500: $md-lime-500,
  md-lime-600: $md-lime-600,
  md-lime-700: $md-lime-700,
  md-lime-800: $md-lime-800,
  md-lime-900: $md-lime-900,
  md-lime-a-100: $md-lime-a-100,
  md-lime-a-200: $md-lime-a-200,
  md-lime-a-400: $md-lime-a-400,
  md-lime-a-700: $md-lime-a-700,
  md-yellow-50: $md-yellow-50,
  md-yellow-100: $md-yellow-100,
  md-yellow-200: $md-yellow-200,
  md-yellow-300: $md-yellow-300,
  md-yellow-400: $md-yellow-400,
  md-yellow-500: $md-yellow-500,
  md-yellow-600: $md-yellow-600,
  md-yellow-700: $md-yellow-700,
  md-yellow-800: $md-yellow-800,
  md-yellow-900: $md-yellow-900,
  md-yellow-a-100: $md-yellow-a-100,
  md-yellow-a-200: $md-yellow-a-200,
  md-yellow-a-400: $md-yellow-a-400,
  md-yellow-a-700: $md-yellow-a-700,
  md-amber-50: $md-amber-50,
  md-amber-100: $md-amber-100,
  md-amber-200: $md-amber-200,
  md-amber-300: $md-amber-300,
  md-amber-400: $md-amber-400,
  md-amber-500: $md-amber-500,
  md-amber-600: $md-amber-600,
  md-amber-700: $md-amber-700,
  md-amber-800: $md-amber-800,
  md-amber-900: $md-amber-900,
  md-amber-a-100: $md-amber-a-100,
  md-amber-a-200: $md-amber-a-200,
  md-amber-a-400: $md-amber-a-400,
  md-amber-a-700: $md-amber-a-700,
  md-orange-50: $md-orange-50,
  md-orange-100: $md-orange-100,
  md-orange-200: $md-orange-200,
  md-orange-300: $md-orange-300,
  md-orange-400: $md-orange-400,
  md-orange-500: $md-orange-500,
  md-orange-600: $md-orange-600,
  md-orange-700: $md-orange-700,
  md-orange-800: $md-orange-800,
  md-orange-900: $md-orange-900,
  md-orange-a-100: $md-orange-a-100,
  md-orange-a-200: $md-orange-a-200,
  md-orange-a-400: $md-orange-a-400,
  md-orange-a-700: $md-orange-a-700,
  md-deep-orange-50: $md-deep-orange-50,
  md-deep-orange-100: $md-deep-orange-100,
  md-deep-orange-200: $md-deep-orange-200,
  md-deep-orange-300: $md-deep-orange-300,
  md-deep-orange-400: $md-deep-orange-400,
  md-deep-orange-500: $md-deep-orange-500,
  md-deep-orange-600: $md-deep-orange-600,
  md-deep-orange-700: $md-deep-orange-700,
  md-deep-orange-800: $md-deep-orange-800,
  md-deep-orange-900: $md-deep-orange-900,
  md-deep-orange-a-100: $md-deep-orange-a-100,
  md-deep-orange-a-200: $md-deep-orange-a-200,
  md-deep-orange-a-400: $md-deep-orange-a-400,
  md-deep-orange-a-700: $md-deep-orange-a-700,
  md-brown-50: $md-brown-50,
  md-brown-100: $md-brown-100,
  md-brown-200: $md-brown-200,
  md-brown-300: $md-brown-300,
  md-brown-400: $md-brown-400,
  md-brown-500: $md-brown-500,
  md-brown-600: $md-brown-600,
  md-brown-700: $md-brown-700,
  md-brown-800: $md-brown-800,
  md-brown-900: $md-brown-900,
  md-grey-50: $md-grey-50,
  md-grey-100: $md-grey-100,
  md-grey-200: $md-grey-200,
  md-grey-300: $md-grey-300,
  md-grey-400: $md-grey-400,
  md-grey-500: $md-grey-500,
  md-grey-600: $md-grey-600,
  md-grey-700: $md-grey-700,
  md-grey-800: $md-grey-800,
  md-grey-900: $md-grey-900,
  md-blue-grey-50: $md-blue-grey-50,
  md-blue-grey-100: $md-blue-grey-100,
  md-blue-grey-200: $md-blue-grey-200,
  md-blue-grey-300: $md-blue-grey-300,
  md-blue-grey-400: $md-blue-grey-400,
  md-blue-grey-500: $md-blue-grey-500,
  md-blue-grey-600: $md-blue-grey-600,
  md-blue-grey-700: $md-blue-grey-700,
  md-blue-grey-800: $md-blue-grey-800,
  md-blue-grey-900: $md-blue-grey-900,
  md-black-base: $md-black-base,
  md-white-base: $md-white-base
);


/// @type Color
$md-light-theme-text-color: rgba($md-black-base, .87) !default;

/// @type Color
$md-light-theme-secondary-text-color: rgba($md-black-base, .54) !default;

/// @type Color
$md-light-theme-icon-text-color: $md-light-theme-secondary-text-color !default;

/// @type Color
$md-light-theme-disabled-text-color: rgba($md-black-base, .38) !default;

/// @type Color
$md-light-theme-hint-text-color: $md-light-theme-disabled-text-color !default;

/// @type Color
$md-light-theme-divider-color: rgba($md-black-base, .12) !default;

/// @type Color
$md-light-theme-status-bar-color: $md-grey-300 !default;

/// @type Color
$md-light-theme-app-bar-color: $md-grey-100 !default;

/// @type Color
$md-light-theme-background-color: $md-grey-50 !default;

/// @type Color
$md-light-theme-card-color: $md-white-base !default;



/// @type Color
$md-dark-theme-text-color: $md-white-base !default;

/// @type Color
$md-dark-theme-secondary-text-color: rgba($md-white-base, .7) !default;

/// @type Color
$md-dark-theme-icon-text-color: $md-dark-theme-secondary-text-color !default;

/// @type Color
$md-dark-theme-disabled-text-color: rgba($md-white-base, .3) !default;

/// @type Color
$md-dark-theme-hint-text-color: $md-dark-theme-disabled-text-color !default;

/// @type Color
$md-dark-theme-divider-color: rgba($md-white-base, .12) !default;

/// @type Color
$md-dark-theme-status-bar-color: $md-black-base !default;

/// @type Color
$md-dark-theme-app-bar-color: $md-grey-900 !default;

/// @type Color
$md-dark-theme-background-color: #303030 !default;

/// @type Color
$md-dark-theme-card-color: $md-grey-800 !default;

/// A mapping of all the light-theme colors.
/// @type Map
$md-light-theme-colors: (
  text: $md-light-theme-text-color,
  secondary: $md-light-theme-secondary-text-color,
  icon: $md-light-theme-icon-text-color,
  disabled: $md-light-theme-disabled-text-color,
  hint: $md-light-theme-hint-text-color,
  divider: $md-light-theme-divider-color,
  background: $md-light-theme-background-color,
  card: $md-light-theme-card-color,
  hover: $md-light-theme-divider-color,
  toolbar: $md-light-theme-app-bar-color,
);

/// A mapping of all the dark-theme colors.
/// @type Map
$md-dark-theme-colors: (
  text: $md-dark-theme-text-color,
  secondary: $md-dark-theme-secondary-text-color,
  icon: $md-dark-theme-icon-text-color,
  disabled: $md-dark-theme-disabled-text-color,
  hint: $md-dark-theme-hint-text-color,
  divider: $md-dark-theme-divider-color,
  background: $md-dark-theme-background-color,
  card: $md-dark-theme-card-color,
  hover: rgba($md-black-base, .12),
  toolbar: $md-dark-theme-app-bar-color,
);


/// A utility function for getting a color from either the light or dark theme.
///
/// @param {String} $name - The color name to look up.
/// @param {Boolean} $light-theme - Boolean if it is for the light theme.
/// @return {Color} the color for the theme or null if it is not a color that changes
///     based on the theme.
@function get-color($name, $light-theme) {
  $map: if($light-theme, $md-light-theme-colors, $md-dark-theme-colors);
  @if map-has-key($map, $name) == false {
    @warn "The given color: #{$name} is not a valid color in the #{if($light-theme, 'light', 'dark')} theme.";
    @return null;
  }

  @return map-get($map, $name);
}

/// A mixin for generating the base color class names to be used
/// on multiple components. This will generate the following class names:
/// - md-text - Styles the `color` to be the text color.
/// - md-text--secondary - Styles the `color` to be the secondary text color.
/// - md-text--disabled - Styles the `color` to be the disabled text color.
/// - md-text--error - Styles the `color` to be the error text color.
/// - md-text--theme-primary - Styles the `color` to be the `primary` color.
/// - md-text--theme-secondary - Styles the `color` to be the `secondary` color.
/// - md-background - Styles the `background` to be the base background color.
/// - md-background--primary - Styles the `background` to be the `primary` color.
/// - md-background--primary-hover - Styles the `background` to be the `primary` color with some opacity.
/// - md-background--secondary - Styles the `background` to be the `secondary` color.
/// - md-background--secondary-hover - Styles the `background` to be the `secondary` color with some opacity.
/// - md-text--theme-primary-ink - Styles any ink inside this class to use the `primary` color.
/// - md-text--theme-secondary-ink - Styles any ink inside this class to use the `secondary` color.
///
/// @example scss - Example Usage SCSS
///   @include react-md-colors;
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to use.
/// @param {Color} secondary-color [$md-secondary-color] - The secondary color to use.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if styling with the light theme.
/// @param {Boolean} include-media [$md-media-included] - Boolean if the media queries should also be included.
/// @param {Boolean} include-color-class-names [$md-colors-include-class-names] - Boolean if a class name
///     should be created for every color as well.
/// @param {Number} primary-opacity [$md-primary-hover-opacity] - The opacity to apply to the primary color with the `md-background--primary`
///     class name.
/// @param {Color} primary-background-text-color [$md-primary-background-text-color] - The text color for any text
///     that appears inside the `md-background--primary` class name.
/// @param {Number} secondary-opacity [$md-secondary-hover-opacity] - The opacity to apply to the secondary color with the `md-background--secondary`
///     class name.
/// @param {Color} secondary-background-text-color [$md-secondary-background-text-color] - The text color for any text
///     that appears inside the `md-background--secondary` class name.
/// @param {Color} error-color [$md-error-color] - The text color for any text that appears in the `md-text--error` class name.
/// @group colors, themes
@mixin react-md-colors(
  $primary-color: $md-primary-color,
  $secondary-color: $md-secondary-color,
  $light-theme: $md-light-theme,
  $include-media: $md-media-included,
  $include-color-class-names: $md-colors-include-class-names,
  $primary-opacity: $md-primary-hover-opacity,
  $primary-background-text-color: $md-primary-background-text-color,
  $secondary-opacity: $md-secondary-hover-opacity,
  $secondary-background-text-color: $md-secondary-background-text-color,
  $error-color: $md-error-color
) {
  .md-text {
    color: get-color('text', $light-theme);

    &--secondary {
      color: get-color('secondary', $light-theme);
    }

    &--disabled {
      color: get-color('disabled', $light-theme);
    }

    &--theme-primary {
      color: $primary-color;
    }

    &--theme-secondary {
      color: $secondary-color;
    }

    &--error {
      color: $error-color;
    }

    &--inherit.md-text--inherit {
      color: inherit;
    }
  }

  .md-ink--primary .md-ink {
    background: rgba($primary-color, .26);
  }

  .md-ink--secondary .md-ink {
    background: rgba($secondary-color, .26);
  }

  .md-background {
    background: get-color('background', $light-theme);

    &--card {
      background: get-color('card', $light-theme);
    }

    &--primary {
      background: $primary-color;
      color: $primary-background-text-color;
    }

    &--secondary {
      background: $secondary-color;
      color: $secondary-background-text-color;
    }
  }

  @if $include-media {
    @include react-md-colors-hover($primary-color, $primary-opacity, $secondary-color, $secondary-opacity);
  }

  @if $include-color-class-names {
    @include react-md-color-class-names;
  }
}

/// This will conditionally update theme colors. The theme colors will only
/// be updated if their value does not equal the global value.
///
/// @example scss - Example Usage SCSS
///   @include react-md-theme-colors($md-blue-500, $md-red-a-200, false);
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to use.
/// @param {Color} secondary-color [$md-secondary-color] - The secondary color to use.
/// @param {Boolean} light-theme [$md-light-theme] - Boolean if styling with the light theme.
/// @param {Boolean} include-media [$md-media-included] - Boolean if the media queries for the hover effects should
///     also be included
/// @param {Number} primary-opacity [$md-primary-hover-opacity] - The opacity to apply to the primary color.
/// @param {Color} primary-background-text-color [$md-primary-background-text-color] - The text color for any text
///     that appears inside the `md-background--primary` class name.
/// @param {Number} secondary-opacity [$md-secondary-hover-opacity] - The opacity to apply to the secondary color.
/// @param {Color} secondary-background-text-color [$md-secondary-background-text-color] - The text color for any text
///     that appears inside the `md-background--secondary` class name.
/// @param {Color} error-color [$md-error-color] - The text color for any text that appears in the `md-text--error` class name.
/// @group colors, themes
@mixin react-md-theme-colors(
  $primary-color: $md-primary-color,
  $secondary-color: $md-secondary-color,
  $light-theme: $md-light-theme,
  $include-media: $md-media-included,
  $primary-opacity: $md-primary-hover-opacity,
  $primary-background-text-color: $md-primary-background-text-color,
  $secondary-opacity: $md-secondary-hover-opacity,
  $secondary-background-text-color: $md-secondary-background-text-color,
  $error-color: $md-error-color
) {
  @if $primary-color != $md-primary-color {
    .md-text--theme-primary {
      color: $primary-color;
    }

    .md-ink--primary .md-ink {
      background: rgba($primary-color, .26);
    }

    .md-background--primary {
      background: $primary-color;

      @if $primary-background-text-color != $md-primary-background-text-color {
        color: $primary-background-text-color;
      }
    }
  } @else if $primary-background-text-color != $md-primary-background-text-color {
    .md-background--primary {
      color: $primary-background-text-color;
    }
  }

  @if $secondary-color != $md-secondary-color {
    .md-text--theme-secondary {
      color: $secondary-color;
    }

    .md-ink--secondary .md-ink {
      background: rgba($secondary-color, .26);
    }

    .md-background--secondary {
      background: $secondary-color;

      @if $secondary-background-text-color != $md-secondary-background-text-color {
        color: $secondary-background-text-color;
      }
    }
  } @else if $secondary-background-text-color != $md-secondary-background-text-color {
    .md-background--secondary {
      color: $secondary-background-text-color;
    }
  }

  @if $error-color != $md-error-color {
    .md-text--error {
      color: $error-color;
    }
  }

  @if $light-theme != $md-light-theme {
    .md-text {
      color: get-color('text', $light-theme);

      &--secondary {
        color: get-color('secondary', $light-theme);
      }

      &--disabled {
        color: get-color('disabled', $light-theme);
      }
    }

    .md-background {
      background: get-color('background', $light-theme);

      &--card {
        background: get-color('card', $light-theme);
      }
    }
  }

  @if $include-media and ($primary-color != $md-primary-color or $secondary-color != $md-secondary-color) {
    @include react-md-colors-hover($primary-color, $primary-opacity, $secondary-color, $secondary-opacity);
  }
}

/// Creates the two class names for getting a primary or secondary background hover effect,
/// Since the `:hover` selector still gets applied on mobile devices, these styles are wrapped
/// in the desktop media query.
///
/// @example markdown - Created Class Names
///   - `.md-background--primary-hover`
///   - `.md-background--secondary-hover`
///
/// @param {Color} primary-color [$md-primary-color] - The primary color to use as the base for the
///     hover effect.
/// @param {Number} primary-opacity [$md-primary-hover-opacity] - The opacity to apply to the primary color.
/// @param {Color} secondary-color [$md-secondary-color] - The secondary color to use as the base for the
///     hover effect.
/// @param {Number} secondary-opacity [$md-secondary-hover-opacity] - The opacity to apply to the secondary color.
@mixin react-md-colors-hover($primary-color: $md-primary-color, $primary-opacity: $md-primary-hover-opacity, $secondary-color: $md-secondary-color, $secondary-opacity: $md-secondary-hover-opacity) {
  @media #{$md-desktop-media} {
    .md-background--primary-hover:hover {
      background: rgba($primary-color, $primary-opacity);
    }

    .md-background--secondary-hover:hover {
      background: rgba($secondary-color, $secondary-opacity);
    }
  }
}

/// A utility function to take a color and get a different swatch/accent color
/// in the same family.
/// @param {Color} color - The material design color to get a swatch for.
/// @param {Number} swatch - The swatch to get.
/// @param {Boolean} accent [false] - Boolean if the swatch is the accent color.
/// @param {Color} fallback-color [null] - An optional color to fallback if the given color
///     is not a valid material design color.
/// @param {String} fallback-name [null] - An optional name for the fallback color's variable
///     to help set a correct fallback value.
/// @return {Color} the color with the correct swatch.
@function get-swatch($color, $swatch, $accent: false, $fallback-color: null, $fallback-name: null) {
  $index: index(map-values($md-color-map), $color);
  @if $index == null or $index < 1 {
    @if $fallback-color == null and $md-colors-warn-md {
      $msg: 'The given color "#{$color}" is not a valid material design color. Please set';
      @if $fallback-name {
        $msg: '#{$msg} the "$#{$fallback-name}"';
      } @else {
        $msg: '#{$msg} a fallback-color';
      }

      $msg: '#{$msg} or disable the "$md-colors-warn-md" variable to ignore this message (not recommended because of losing styling).';
      @warn "#{$msg} See https://react-md.dev/v1/customization/colors?tab=1#variable-md-colors-warn-md for more information.";
      @return $color;
    } @elseif $fallback-color == null {
      @return $color;
    }

    @return $fallback-color;
  }

  $current-color-name: nth(map-keys($md-color-map), $index);
  $colors: (
    'red'
    'pink'
    'purple'
    'deep-purple'
    'indigo'
    'blue'
    'light-blue'
    'cyan'
    'teal'
    'green'
    'light-green'
    'lime'
    'yellow'
    'amber'
    'orange'
    'deep-orange'
    'brown'
    'grey'
    'blue-grey'
  );
  $color-name: null;
  $index: 1;
  @while $color-name == null and $index < length($colors) {
    $md-color: nth($colors, $index);
    @if str-index($current-color-name, $md-color) != null {
      $color-name: $md-color;
    }

    $index: $index + 1;
  }

  @return map-get($md-color-map, "md-#{$color-name}-#{if($accent, 'a-', '')}#{$swatch}");
}

/// This will create two class names for every material design color. The first
/// class name can be used to style any text with that color, while the other will
/// be used to style the background color.
///
/// @example scss - Example Usage SCSS
///    @include react-md-color-class-names;
///
/// @example scss - Example Usage CSS Output
///    .md-red-50--text {
///      color: #ffebee;
///    }
///
///    .md-red-50--background {
///      background: #ffebee;
///    }
///
/// @param {Boolean} include-text [true] - Boolean if the text class name should be created.
/// @param {Boolean} include-background [true] - Boolean if the background class name should be created.
@mixin react-md-color-class-names($include-text: true, $include-background: true) {
  @each $name, $color in $md-color-map {
    @include react-md-color-class-name($name, $color, $include-text, $include-background);
  }
}

/// Creates 0 - 2 class names for a given color. No classes will be created if both
/// `$include-text` and `$include-background` are false.
///
/// @example scss - Example Usage SCSS
///   @include react-md-color-class-name(md-red-500, $md-red-500);
///   @include react-md-color-class-name(md-blue-200, $md-blue-200, false, true);
///
/// @example scss - Example Usage CSS Output
///   .md-red-500--text {
///     color: #f44336;
///   }
///
///   .md-red-500--background {
///     background: #f44336;
///   }
///
///   .md-blue-200--background {
///     background: #90caf9;
///   }
///
/// @param {String} name - The class name prefix to use.
/// @param {Color} color - The color to apply to text, background, or both.
/// @param {Boolean} include-text [true] - Boolean if the text class name should be created.
/// @param {Boolean} include-background [true] - Boolean if the background class name should be created.
@mixin react-md-color-class-name($name, $color, $include-text: true, $include-background: true) {
  @if $include-text {
    .#{$name}--text {
      color: $color;
    }
  }

  @if $include-background {
    .#{$name}--background {
      background: $color;
    }
  }
}
