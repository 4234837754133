@import "globals";
@import "buttons";
@import "fonts";
@import "node_modules/tailwindcss/base";
@import "node_modules/tailwindcss/components";
@import "node_modules/tailwindcss/utilities";
//@include host-google-font(Roboto, 400);
@include host-material-icons();
@include react-md-everything;

body {
  margin: 00;
  padding: 00;
  font-family: sans-serif;
  overflow-y: hidden;
}

#root {
  height: 100vh;
  width: 100vw;
}

#image-logo {
  margin: 0 auto !important;
}

.GRID-EXPANDER {
  position: absolute;
  top: 30px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
}

.ag-theme-material .ag-icon-checkbox-unchecked {
  background-size: 10px 10px !important;
}
.ag-theme-material .ag-selection-checkbox span {
  top: 0px !important;
}

@media screen and (min-width: 768px) {
  .md-toolbar {
    height: 90px;
  }
  .md-toolbar .md-btn--text {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .md-toolbar-relative {
    margin-top: 46px;
  }
  .md-title--toolbar {
    line-height: 50px;
  }
  .md-btn--toolbar {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .md-toolbar {
    height: 90px;
  }
  .md-toolbar .md-btn--text {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .md-toolbar-relative {
    margin-top: 46px;
  }
  .md-title--toolbar {
    line-height: 50px;
  }
  .md-btn--toolbar {
    margin-top: 5px;
  }
}

.hl {
  background-color: #ffff00;
  color: #000;
}

@import "./redesign";

/* Record Counter */
.recordLabel {
  position: relative;
  font-family: inherit;
  font-size: 0.8rem;
}

.chosen-version {
  background-color: #bababa;
}

.unchosen-version {
  background-color: unset;
}

.update-dialog,
.md-dialog-footer {
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.version-alert-text {
  text-align: center;
  color: red;
}

.version-msg {
  font-size: 14px;
  text-align: center;
  padding-bottom: 5px;
}

.date-picker-selector {
  border-bottom: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
  width: 100%;
  flex: 1;
}
.react-codemirror2 {
  height: calc(100% - 50px);
}
