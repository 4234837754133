// Global css properties from the HMW redesign
:root {
  --color-primary: hsl(223, 70%, 57%);
  --color-secondary: hsl(168, 100%, 25%);
  --color-tertiary: hsl(39, 100%, 38%);
  --color-quaternary: hsl(227, 41%, 29%);
  --color-quinary: hsl(320, 100%, 29%);
  --color-senary: hsl(257, 81%, 64%);
  --color-gray-100: hsl(231, 100%, 99%);
  --color-gray-200: hsl(
    236,
    23%,
    87%
  ); // When appearing over black/dark blue bg
  --color-gray-700: hsl(223, 4%, 34%); // When appearing over black/dark blue bg
  --color-text-heading: hsl(229, 60%, 16%);
}

.gradient-primary {
  @apply from-blue-400 to-primary;
}
.gradient-secondary {
  @apply from-green-400 to-secondary;
}
.gradient-tertiary {
  @apply from-yellow-400 to-tertiary;
}
.gradient-quaternary {
  @apply from-purple-400 to-quaternary;
}
.gradient-quinary {
  @apply from-pink-400 to-quinary;
}
.gradient-senary {
  @apply from-purple-400 to-senary;
}

.ag-header-row:first-child {
  @apply text-quaternary bg-gray-200;
}

.ag-header-row:last-child {
  @apply bg-gray-100;
}

.ag-icon {
  @apply text-primary;
}

.ag-pinned-left-cols-viewport {
  .ag-cell {
    background: linear-gradient(to top, #dfe1ef, #e9eaf8);
    @apply text-dark;
  }
  .ag-row {
    &:hover {
      .ag-cell {
        background: linear-gradient(to bottom, #bdc2d3, #c3c7d9);
      }
    }
  }
}
.ag-pinned-left-cols-viewport .ag-row.ag-row-selected .ag-cell,
.ag-theme-material .ag-row.ag-row-selected {
  background-image: none;
  @apply bg-primary text-white;
}

.text-light .md-tile-text--primary {
  color: var(--color-gray-100);
}

.md-btn--toolbar {
  height: auto !important;
  line-height: unset !important;
}

.top-level-nav {
  .top-level-nav__item {
    @apply rounded-l-xl;
    > a {
      @apply flex items-center text-light h-12 px-0;
      &.md-list-tile--active {
        background: transparent !important;
      }
      .md-tile-addon--icon {
        @apply h-auto relative z-10;
      }
      .md-tile-content {
        @apply p-0 pl-4;
      }
    }
    &.is-active,
    &.is-active-dashboard {
      @apply relative;
    }
    &.is-active-dashboard {
      @apply bg-white;
      .md-tile-text--primary {
        color: var(--color-gray-700);
      }
      @include curveOuter(var(--color-quaternary), white, 40);
    }
    &.is-active {
      background: var(--color-primary);
      @include curveOuter(var(--color-quaternary), var(--color-primary), 40);
    }
  }
}

.menu > li {
  position: relative;
  z-index: 10;
  &:hover {
    background: inherit;
  }
}

.menu > li.active {
  background: white;
  padding: 6px;
  box-shadow: inset 0 0 0 6px white;
  color: var(--color-quaternary);
  z-index: 5;
  &:hover {
    background: white;
  }
}

.menu:not(.menu__submenu) > li.active {
  @apply rounded-l-xl;
  @include curveOuter(var(--color-primary), white, 40);
}
