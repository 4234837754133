@import "node_modules/react-md/src/scss/react-md";
@import "mixins";
// Variable overrides
$md-primary-color: $md-blue-500;
$md-secondary-color: $md-blue-900;

$ag-mat-primary: $md-primary-color;
$ag-mat-accent: $md-secondary-color;

$row-height: 30px;
$header-height: 30px;
$font-size: 14px;
$font-family: Helvetica, arial, sans-serif;

.gapped:after {
  width: 5px;
}

.date-picker {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  cursor: pointer;
  height: 40px;
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}

.md-dialog--centered .md-list-tile.md-text {
  height: 25px;
}

.ag-theme-material .ag-header-row .ag-header-cell,
.ag-theme-material .ag-header-row .ag-header-group-cell {
  padding-left: 12px;
  padding-right: 12px;
}

.ag-theme-material .ag-header-cell .ag-floating-filter-button {
  margin-top: 5px;
}

.ag-theme-material .ag-row .ag-cell {
  line-height: $row-height;
  border-left: 1px solid rgb(224, 224, 224);
  padding-left: 12px;
  padding-right: 12px;
}

.ag-theme-material .ag-row .ag-cell.ag-cell-focus {
  border-left: 1px solid $ag-mat-accent;
}

.ag-theme-material .ag-row .ag-cell.ag-cell-inline-editing {
  height: $row-height;
  padding-top: 0;
  padding-bottom: 0;
}

.ag-theme-material .ag-header-row .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  line-height: $row-height;
  font-size: 13px;
}

*,
button,
h2,
.ag-theme-material,
.ag-theme-material .ag-header {
  font-family: $font-family;
}

.ag-row-odd {
  background: #f7f7f7;
}

.ag-theme-material .ag-row.ag-row-selected {
  background: $md-primary-color;
  color: #fff;
}

.ag-theme-material.column-selector .ag-cell {
  background: #fff;
  color: #000;
  border: none;
}

.ag-theme-material.column-selector .ag-row.ag-row-selected .ag-cell-focus {
  border: none;
}

.ag-theme-material.column-selector .ag-ltr .ag-cell-last-left-pinned {
  border: none;
}

.ag-theme-material.column-selector .ag-row {
  border: none;
}

.ag-theme-material.column-selector .ag-row .ag-cell.ag-cell-focus {
  border: none;
}

.ag-theme-material.column-selector .ag-row.ag-row-selected {
  background: #fff;
  color: #000;
  border: none;
}

.ag-theme-material .ag-row.ag-row-selected .ag-cell-focus {
  border-width: 2px;
}

.incell-editor {
  border: none;
  background: transparent;
}

.date-editor-input {
  width: 111px;
  height: 28px;
}

// .inline-toolbar,
// .inline-toolbar .md-title--toolbar,
// .inline-toolbar .md-btn--toolbar {
//   height: 45px;
//   line-height: 45px;
// }

// .inline-toolbar .md-btn--toolbar {
//   margin: -7px 0 0 0;
// }

.inline-toolbar .md-btn--toolbar .md-btn {
  margin: auto;
}

.my-slider .md-slider-track-fill {
  background: none;
}

.md-list--nested-1 .md-list-tile {
  padding-left: 40px;
}

.CodeMirror pre.CodeMirror-line span {
  font-family: Consolas, "Courier New", Courier, monospace;
}

.label-datepicker>.react-datepicker-wrapper {
  padding-top: 33px;
}

.hamburger {
  display: block;
  width: 3rem;
  height: 3rem;

  .line {
    fill: none;
    stroke: var(--color-gray-100);
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  /* Hamburger */
  .line.top,
  .line.bottom {
    stroke-dasharray: 50px 600px;
    stroke-dashoffset: 0px;
  }

  .line.cross {
    stroke-dasharray: 50px 60px;
    stroke-dashoffset: 0px;
  }

  /* Cross */
  &.on .line.top,
  &.on .line.bottom {
    stroke-dasharray: 70.71px 600px;
    stroke-dashoffset: -392px;
  }

  &.on .line.cross {
    stroke-dasharray: 50px 60px;
    stroke-dashoffset: 51px;
  }

  /* Timing */
  $timing: 0.35s;

  .line.cross {
    transition: $timing stroke-dasharray ease $timing,
      $timing stroke-dashoffset ease $timing, $timing stroke ease 0s;
  }

  .line.top {
    transition: ($timing * 1.5) stroke-dasharray ease 0s,
      ($timing * 1.5) stroke-dashoffset ease 0s, $timing stroke ease 0s;
  }

  .line.bottom {
    transition: ($timing * 1.5) stroke-dasharray ease $timing,
      ($timing * 1.5) stroke-dashoffset ease $timing, $timing stroke ease 0s;
  }

  &.on .line.cross {
    transition: $timing stroke-dasharray ease 0s,
      $timing stroke-dashoffset ease 0s, $timing stroke ease 0s;
  }
}