.btn {
  @apply p-3 px-6 rounded-xl border-2 whitespace-nowrap flex items-center;
  &--primary {
    @apply text-light bg-primary;
    [class*='icon'] {
      @apply text-light;
    }
  }
  &--secondary {
    @apply text-gray-500 border-gray-100 justify-center bg-white;
    [class*='icon'] {
      @apply text-primary;
    }
  }
  [class*='icon'] {
    @apply inline-block ml-4 w-5 h-5;
  }
}

.btn:hover {
  filter: brightness(110%);
  /* Increase brightness by 10% */
}

.md-file-input-container {
  label {
    padding: 0.75rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    box-shadow: none !important;
    border-radius: 0.65rem !important;
    margin-top: 0 !important;
    &.md-background--primary {
      background: var(--color-primary);
    }
  }
}
