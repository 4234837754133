.form-input {
  width: calc(60% - 50px);
  line-height: 16px;
  border: 1px solid grey;
  margin-bottom: 10px;
  outline: none;
  border-radius: 6px;
  font-size: 17px;
  padding: 14px 16px;
  background-color: rgb(232, 240, 254);
}

.form-input:focus {
  border-color: #06559d;
  box-shadow: 0 0 0 2px #e7f3ff;
  caret-color: #06559d;
}

.btn-login {
  background-color: #06559d;
  color: white;
  width: calc(60% - 50px);
  margin-bottom: 10px;
}

.forgotPass {
  cursor: pointer;
  color: #06559d;
  font-weight: bold;
}

.forgotPass:hover {
  text-decoration: underline;
}

#login-alert,
#logout-alert {
  justify-content: center;

  text-align: center;
  .md-dialog-footer {
    display: flex;

    justify-content: center;
  }
}
