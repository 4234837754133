@mixin curveOuter($bgColor, $curveColor, $size, $curveAfterColor: false) {
  position: relative;
  &::before,
  > a::before,
  > span::before,
  > .accordion-item::before,
  &::after,
  > span::after,
  > .accordion-item::after,
  > a::after {
    content: "";
    position: absolute;
    right: 0;
    z-index: 0;
    pointer-events: none;
  }
  > a,
  > .accordion-item,
  > span {
    &::before,
    &::after {
      width: #{$size}px;
      height: #{$size}px;
      border-radius: 50%;
      background: $bgColor;
      z-index: 1;
    }
    &::before {
      top: -#{$size}px;
    }
    &::after {
      bottom: -#{$size}px;
    }
  }
  &::before,
  &::after {
    background: $curveColor;
    width: #{$size / 2}px;
    height: #{$size / 2}px;
  }

  @if $curveAfterColor {
    &::after {
      background: $curveAfterColor;
    }
  }
  &::before {
    top: -#{$size / 2}px;
  }
  &::after {
    bottom: -#{$size / 2}px;
  }
}
