@mixin host-material-icons($font-url: '../fonts') {
    $icon-link: $font-url + '/MaterialIcons-Regular';
    @font-face {
      font-family: 'Material Icons';
      font-style: normal;
      font-weight: 400;
      src: url($icon-link + '.eot');
      // scss-lint:disable SpaceAfterComma
      src: local('Material Icons'),
           local('MaterialIcons-Regular'),
           url($icon-link + '.woff2') format('woff2'),
           url($icon-link + '.woff') format('woff'),
           url($icon-link + '.ttf') format('truetype');
      // scss-lint:enable SpaceAfterComma
    }
  
    .material-icons {
      direction: ltr;
      display: inline-block;
      font-family: 'Material Icons';
      // Support for IE.
      font-feature-settings: 'liga';
      font-size: $md-font-icon-size;
      // Support for Firefox.
      -moz-osx-font-smoothing: grayscale;
      // Support for all WebKit browsers.
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1;
      // Support for Safari and Chrome.
      text-rendering: optimizeLegibility;
      text-transform: none;
      white-space: nowrap;
      word-wrap: normal;
      }
  }


@mixin host-google-font($font-name: Roboto, $font-weight: 400, $fonts-url: '../fonts') {
    
    $full-font-name: str-replace($font-name, ' ') + get-font-suffix($font-weight);
  
    $fonts-url: if($fonts-url == null, '../fonts/', $fonts-url);
    $fonts-url: if(char-at($fonts-url) != '/', $fonts-url + '/', $fonts-url);
  
    @font-face {
      font-family: quote($font-name);
      font-style: normal;
      font-weight: $font-weight;
  
      src: local(#{quote($font-name)}),
       local(#{quote($full-font-name)}),
       url($fonts-url + $full-font-name + '.woff2') format('woff2'),
       url($fonts-url + $full-font-name + '.woff') format('woff'),
       url($fonts-url + $full-font-name + '.ttf') format('truetype');
    }
  }